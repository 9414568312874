import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  cacheControl,
  filterMarkets,
  getCanonicalUrl,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { getApi, markets } from '@hultafors/solidgear/api';
import { createMeta } from '@hultafors/solidgear/helpers';
import { usePageMicroData } from '@hultafors/solidgear/hooks';
import {
  ContentPageQuery,
  GlobalFields,
  PageProps,
} from '@hultafors/solidgear/types';

import { fallbackDomain, prodDomains } from '../next.domains';

const ContentHero = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.ContentHero),
);
const ContentArea = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.ContentArea),
);
const DynamicContent = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.DynamicContent),
);
const GuideButton = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.GuideButton),
);
const RelatedProductsBlock = dynamic(() =>
  import('@hultafors/solidgear/components').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const Page = dynamic(() =>
  import('@hultafors/solidgear/components').then((mod) => mod.Page),
);

export interface InfoPageProps extends PageProps {
  content: ContentPageQuery['contentPage'];
  productCatalogNodeId: string;
  slug: string;
}
export const getServerSideProps: GetServerSideProps<
  InfoPageProps,
  { slug: string }
> = async ({ res, req, locale, params: { slug: rawSlug } }) => {
  try {
    const { settings, dato } = getApi(req, locale);
    const slug = rawSlug.replace('/', '');

    const path = `/${slug}`;
    const canonical = getCanonicalUrl(
      settings.language.lang.toLowerCase(),
      prodDomains,
      fallbackDomain,
      path,
    );
    const {
      data: { contentPage: content, ...globalFields },
    } = await dato.getContentPage(slug);

    let parentSlug = null;
    if (content?.parentSlug) {
      parentSlug = content.parentSlug;
    }

    if (!content || content === null || !!parentSlug === true) {
      return {
        notFound: true,
      };
    }
    const productCatalogNodeId = content?.products || null;
    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'SOLIDGEAR'),
      content?.pageLocales,
    );

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        settings,
        slug,
        ...(globalFields as GlobalFields),
        canonical,
        content,
        hrefLanguages,
        productCatalogNodeId,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export const InfoPage: NextPage<InfoPageProps> = ({
  canonical,
  content,
  productCatalogNodeId,
  settings,
  hrefLanguages,
  slug,
}) => {
  // TODO createMeta from global lib/helpers
  const microData = usePageMicroData({
    content: content.contentArea,
    seo: content.seo,
    thing: {
      '@type': slug === 'about-us' ? 'AboutPage' : 'WebPage',
    },
  });
  return (
    content && (
      <Page
        metadata={createMeta(content?.seo, settings?.language.lang)}
        microData={microData}
        hrefLanguages={hrefLanguages}
        canonicalPath={canonical}
      >
        <ContentHero
          title={content.heroTitle}
          description={content.heroDescription}
          imageDesktop={content?.imageDesktop}
          imageTablet={content?.imageTablet}
          imageMobile={content?.imageMobile}
          type="contentView"
          splitHero={content?.splitHero}
        />
        {content?.contentArea && <ContentArea content={content?.contentArea} />}
        <GuideButton pageContent={content} />
        <DynamicContent content={content.feed} />
        {productCatalogNodeId && (
          <RelatedProductsBlock
            productCatalogNodeId={productCatalogNodeId}
            header={content.header}
          />
        )}
      </Page>
    )
  );
};

export default InfoPage;
